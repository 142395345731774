import React, { Suspense } from 'react';
import Main from './layouts/Main';
import Home from './screens/Home';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import './assets/css/index.css';



function App() {
  return (
    <Suspense fallback={<h3>Loading....</h3>}>
      <ThemeProvider theme={theme}>
        <Main>
          <Home />
        </Main>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
