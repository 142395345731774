
//import mock from './mock'
import axiosInstance from './axios'



export const getDemos = async () =>
    await axiosInstance.get(`configs/demos.json`)












/*
mock.onGet('/demos').reply(200, {
    content: [
        {
            id: 1,
            title: 'Demo 1',
            description: 'Descrição da demo 1',
            image: 'url para image'
        },
        {
            id: 2,
            title: 'Demo 2',
            description: 'Descrição da demo 2',
            image: 'url para image'
        },
        {
            id: 3,
            title: 'Demo 3',
            description: 'Descrição da demo 3',
            image: 'url para image'
        }
    ]
});
*/