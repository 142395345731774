import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import styles from './styles';
import {
    withStyles,
    Container,
} from '@material-ui/core';
import logoIcon from './../../assets/images/cycloid_logo-gif.gif'
import bulletIcon from './../../assets/images/cycloid-bullets.png';
import * as api from '../../utils/api';

import CardDemo from '../../components/CardDemo'
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import RubberBand from 'react-reveal/RubberBand';
import Rotate from 'react-reveal/Rotate';


const Home = ({
    classes
}) => {

    const [demos, setDemos] = useState(null)



    useEffect(() => {
        const fetchDemos = async () => {
            try {
                const resp = await api.getDemos()
                setDemos(resp.data.demos)
            } catch (error) {
                // Handle error
            }
        }
        fetchDemos()
        return () => {}
    }, [])




    const renderDemosCard = () => {
        return demos.map(demo => (
            <CardDemo />
        ))
    }



    return (
        <div className={classes.root}>
            <Container  className={classes.container}>
                <div className={classes.header}>
                    <img src={logoIcon} alt="" />
                    <h1>AWS Media Demos</h1>
                    <img src={bulletIcon} alt="" className="bullet-icon"/>
                </div>
                <div className={classes.content} >
                    {!demos ? (
                        <p>Loading</p>
                    ) : (
                             demos.map( (demo, index) => (
                                 <Zoom  key={index} left duration={900} delay={300 * index}>
                                     <CardDemo data={demo}/>
                                 </Zoom  >
                            ))
                    )}
                </div>
            </Container>
            <div className={classes.footerBox}>
                &copy;
                    {(new Date().getFullYear())}&nbsp;All rights reserved<br />
                    Powered by&nbsp;
                    < a href="https://www.cycloid.pt/index.html" target="_blank" rel="noreferrer" >&nbsp;CYCLOID Technology and Consulting, Lda.</a >&nbsp;
                </div>
            <div className={classes.overlay}></div>
       </div>
    )
}

Home.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(Home)
