import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import clsx from 'clsx';
import styles from './styles';
import { withStyles } from '@material-ui/core';


const CardDemo = ({
    classes,
    data
}) => {

    const onClickHandle = () => {
        window.open(data.link, '_blank');
    }
    return (
        <div className={classes.root} onClick={onClickHandle}>
            <div className="boxInner">
                <div className={classes.boxImage}>
                    <img src={`./images/${data.image}`} alt="" />
                </div>
                <div className={classes.boxDescription}>
                    {data.description}
                </div>
            </div>
            <div className={classes.boxInfo}>
                <p>{data.title}</p>
                <span className={clsx({ [classes.online]: data.online , [classes.offline]: !data.online })}></span>
            </div>
       </div>
    )
}

CardDemo.propTypes = {
    classes: PropTypes.object
}

export default compose(
    withStyles(styles)
)(CardDemo)
