
const classes = theme => ({
    root: {
        width: '400px',
        height: '320px',
        borderRadius: '12px',
        margin: '20px',
        background: 'linear-gradient(90deg, rgba(176, 25, 26, 1) 0%, rgba(37,2,2,1) 100%)',
        color: '#000',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': {
            '& .boxInner': {
                transform: 'rotateY(180deg)'
            }
        },
        '& .boxInner': {
            transition: 'transform 0.6s',
            transformStyle: 'preserve-3d',
            position: 'relative',
            display: 'flex',
            flexGrow: 1,
            width: '100%',
            height: '80%',
            borderRadius: '12px',
            boxShadow: '0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%)',
        },
        [theme.breakpoints.down('sm')]: {
        width: '300px',
        height: '280px',
        }
    },
    boxImage: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backfaceVisibility: 'hidden',
        '& img': {
            objectFit: 'cover',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            width: '100%',
            height: '100%',
        }
    },
    boxDescription: {
        transform: 'rotateY(180deg)',
        position: 'absolute',
        height: '100%',
        width: '100%',
        backfaceVisibility: 'hidden',
        backgroundColor: 'rgba(0,0,0,1)',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        '& span': {
            marginTop: 'auto',
            marginBottom: '20px'
        }
    },
    boxInfo: {
        height: '60px',
        padding: '10px',
        background: 'linear-gradient(90deg, rgba(176, 25, 26, 1) 0%, rgba(37,2,2,1) 100%)',
        color: 'white',
        borderBottomLeftRadius: '12px',
        borderBottomRightRadius: '12px',
        borderTop: '1px solid #ddd',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    online: {
        height: '25px',
        width: '25px',
        backgroundColor: '#7FFF00',
        borderRadius: '50%'
    },
    offline: {
        height: '25px',
        width: '25px',
        backgroundColor: '#FF0000',
        borderRadius: '50%'
    }
})
export default classes;