import backgroundImage from './../../assets/images/cy-bg-aws-media-demos.jpg';

const classes = theme => ({
    root: {
        zIndex: 2,
        color: '#FFF',
        height: '100%',
        backgroundColor: '#000',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        }
    },
    container: {
        zIndex: 2,
        maxWidth: '1600px'
    },
    header: {
        zIndex: 2,
        display: 'flex',
        width: '100%',
        margin: '26px 0 36px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        '& h1': {
            marginLeft: 'auto',
            fontWeight: 800
        },
        '& img': {
            objectFit: 'contain'
        },
        [theme.breakpoints.down('sm')]: {
            '& .bullet-icon': {
                display: 'none',
            },
            '& h1': {
                marginLeft: 0,
            },
        }
    },
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: '50px',
        justifyContent: 'center'
    },
    overlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.8)',
        top: '0',
        zIndex: 1
    },
    footerBox: {
        zIndex: 2,
        marginTop: 'auto',
        color: 'white',
        fontSize: '11px',
        marginLeft: 'auto',
        paddingBottom: '20px',
        paddingRight: '20px',
        '& p': {
            fontStyle: 'italic',
            color: 'white',
        },
        '& a': {
            color: '#B0191A',
            fontWeight: 600
        }
    },
})
export default classes;
